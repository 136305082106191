import React from 'react';

import Layout from "../components/layout";
import StepTwo from "../components/step-2";

const StepTwoComponent = () => {
  return (
    <Layout title="home">
      <StepTwo />
    </Layout>
  );
}

export default StepTwoComponent;