import React, { useEffect, useReducer, useRef, useState, useCallback } from "react";
import { navigate, Link } from "gatsby"
import ReactInputMask from "react-input-mask"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import request from "../services/request"
import { getStorage, setStorage } from "../utils"
import ButtonField from "./common/form/ButtonField"
import InputField from "./common/form/InputField"
import SelectField from "./common/form/SelectField"
import { THANKS_SCREEN, propertyType, hereAboutType, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "../constant";

const initialState = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  property_type: "",
  here_about_type: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case action.type:
      return { ...state, [action.type]: action.value }
    default:
      throw new Error()
  }
}

const StepTwo = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [formSubmit, setFormSubmit] = useState(false)
  const [formValue, setFormValue] = useState({})
  const inputRef = useRef();
  const intervalRef = React.useRef();
  const alertValue = ["Mobile/Manufactured", "Raw Land"]

  const introData = useStaticQuery(graphql`
    query {
      allStrapiIntroSection {
        nodes {
          menImage {
            alternativeText
            localFile {
              childImageSharp{
                fluid(quality: 0, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          headerMenImage
          imagePositionRight
        }
      }
    }
  `)
  const menImage = introData.allStrapiIntroSection.nodes[0].menImage.localFile.childImageSharp.fluid
  const headerMenImage = introData.allStrapiIntroSection.nodes[0].headerMenImage
  const rightMenImage = introData.allStrapiIntroSection.nodes[0].imagePositionRight

  const onValueChange = (e, type) => {
    if (alertValue.includes(e.target.value) && type === "property_type") {
      alert("Sorry, we do not buy mobile/manufactured homes or raw land at this time.")
    }
    dispatch({ type, value: e.target.value })
  }
  useEffect(() => {
    let initalValue = getStorage("formData")
    setFormValue(initalValue)
    const {
      first_name = "",
      last_name = "",
      phone_number = "",
      email = "",
      property_type = "",
      here_about_type = "",
    } = initalValue
    let obj = { first_name, last_name, phone_number, email, property_type, here_about_type }
    Object.keys(obj).map((keyName, i) =>
      dispatch({
        type: keyName,
        value: obj[keyName] === null ? "" : obj[keyName],
      })
    )
    setStorage("addressSelect", false)
  }, [])

  const onSubmit = e => {
    e.preventDefault()
    setFormSubmit(true)
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef]);

  const submitForm = useCallback(() =>{
    let initalValue = getStorage("formData");
    let finalValue = { ...initalValue, ...state };
    setStorage("formData", finalValue)
    const {
      first_name = null,
      last_name = null,
      email = null,
      phone_number = null,
      property_type = null,
      here_about_type = null,
      address = null,
      utm_source = null,
      utm_medium = null,
      utm_campaign = null,
      utm_network = null,
      utm_keyword = null,
      utm_landing = null,
      utm_device = null,
      utm_creative = null,
      utm_location = null,
      utm_content = null,
      utm_term = null,
    } = finalValue
    let data = {
      address,
      first_name,
      last_name,
      email,
      property_type,
      here_about_type,
      phone: phone_number,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_network,
      utm_keyword,
      utm_landing,
      utm_device,
      utm_creative,
      utm_location,
      utm_content,
      utm_term,
      clientId: window.ga.getAll()[0].get("clientId"),
    }
    request({
      url: "b5vnt9p",
      data,
    })
    .then(async (resp) => {      
      if(resp.status !== "success") { 
        intervalRef.current = setTimeout(() => {
          submitForm()
        }, 10000);
      } else {
        console.log("Step-2 web-hook success");
        clearTimeout(intervalRef.current);
      }
    })
    .catch(err => {
      console.log("onConfirm-re", err)
    })
  },[state])
  useEffect(() => {
    if (formSubmit) {
      submitForm();
      navigate(THANKS_SCREEN);
    }
  }, [formSubmit, state, submitForm]);
  const onBlur = (e, type) => {
    dispatch({ type, value: e.target.value.trim() })
  }
  const { address = " " } = formValue
  return (
    <div className="stepTwoContainer formBackground">
      <img src={`//ad.ipredictive.com/d/track/cvt/pixel?acct_id=57301&cache_buster=${Math.floor(Date.now() / 1000)}`} alt="stepTwo" style={{ display: 'none' }} width="1" height="1" ></img>
      {headerMenImage && <div className={`${rightMenImage ? "introMenImage" : "introMenImage leftIntroMenImage"}`}>
        <Img fluid={menImage} alt={introData?.allStrapiIntroSection?.nodes[0]?.menImage?.alternativeText} />
      </div>}
      <form
        className="needs-validation px-3 text-white stepTwoForm"
        onSubmit={e => onSubmit(e)}
      >
        <div className="sub-header mt-1 font-weight-bold address-text">
          {address !== " " && address !== null ? `${address}` : ""}
        </div>
        <h1>Almost There!</h1>
        <div className="sub-header mt-2 desc-content">
          Where should we send your
          <span className="font-weight-bold"> cash offer?</span>
        </div>
        <div className="row mt-4">
          <div className="col-sm-6 mb-4 col-12 col-md-6 pr-sm-2">
            <InputField
              id="firstName"
              placeholder="First Name"
              type="text"
              maxLength="50"
              pattern="^\w+(\s+\w+)*$"
              onBlur={e => onBlur(e, "first_name")}
              value={state.first_name}
              onChange={e => onValueChange(e, "first_name")}
              className="form-control getAddressInput "
              required={true}
            />
          </div>
          <div className="col-sm-6 mb-4 col-12 col-md-6 pl-sm-2">
            <InputField
              id="secondName"
              placeholder="Last Name"
              type="text"
              maxLength="50"
              pattern="^\w+(\s+\w+)*$"
              onBlur={e => onBlur(e, "last_name")}
              value={state.last_name}
              onChange={e => onValueChange(e, "last_name")}
              className="form-control getAddressInput "
              required={true}
            />
          </div>
          <div className="col-sm-6 mb-4 col-12 col-md-6 pr-sm-2">
            <ReactInputMask
              mask="(999) 999-9999"
              maskChar={null}
              value={state.phone_number}
              onChange={e => onValueChange(e, "phone_number")}
              className="form-control getAddressInput "
              placeholder="Phone Number"
            >
              {inputProps => (
                <InputField
                  id="phoneNumber"
                  type="tel"
                  pattern="^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$"
                  {...inputProps}
                  required={true}
                  errorMsg="Please enter the vaild Phone number"
                />
              )}
            </ReactInputMask>
          </div>
          <div className="col-sm-6 mb-4 col-12 col-md-6 pl-sm-2">
            <InputField
              id="email"
              placeholder="Email"
              maxLength="50"
              value={state.email}
              onChange={e => onValueChange(e, "email")}
              className="form-control getAddressInput "
              type="email"
              required={true}
              errorMsg="Please enter the vaild Email"
            />
          </div>
          <div className="col-sm-6 mb-2 col-12 col-md-6 pr-sm-2">
            <SelectField
              id="propertytype"
              className="form-control getAddressInput"
              options={propertyType}
              value={state.property_type}
              initialText="Select Property Type"
              onChange={e => onValueChange(e, "property_type")}
            />
          </div>
          <div className="col-sm-6 mb-2 col-12 col-md-6 pl-sm-2">
            <SelectField
              id="hereAboutType"
              className="form-control getAddressInput"
              options={hereAboutType}
              value={state.here_about_type}
              initialText="Where'd you hear about us?"
              onChange={e => onValueChange(e, "here_about_type")}
            />
          </div>
        </div>
        <ButtonField
          id="userDetails"
          className="btn-primary getOff font-weight-bold mb-2 w-100"
          name="GET A CASH OFFER"
          type="submit"
          disabled={alertValue.includes(state.property_type)}
        />
        <div className="bottom-label mt-1 mb-4 font-italic">
          * By submitting the form, you are providing expressed consent for Tarek Buys Houses to contact you by telephone, SMS/MMS, (including automated SMS/dialing systems) and/or email even if you are on a Do Not Call list. Should you have questions or wish to opt-out of communication at any time, please contact us at <a href="mailto:info@tarekbuyshouses.com">info@tarekbuyshouses.com</a>, call (888) 356-3547, or text "STOP". Text "HELP" for help or assistance. SMS messaging rates may apply. Message frequency may vary.Please review our  <Link
            to={PRIVACY_POLICY}
            className="cursor-pointer"
          >Privacy Policy</Link> and <Link to={TERMS_AND_CONDITIONS}>Terms and Conditions</Link> to learn more.
        </div>
      </form>
    </div>
  )
}

export default StepTwo